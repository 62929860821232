
export const GET_DEPOSITS_REQUEST = 'GET_DEPOSITS_REQUEST';
export const GET_DEPOSITS_SUCCESS = 'GET_DEPOSITS_SUCCESS';
export const GET_DEPOSITS_FAILURE = 'GET_DEPOSITS_FAILURE';
export const GET_CRYPTO_PAYMENTS_REQUEST = 'GET_CRYPTO_PAYMENTS_REQUEST';
export const GET_CRYPTO_PAYMENTS_SUCCESS = 'GET_CRYPTO_PAYMENTS_SUCCESS';
export const GET_CRYPTO_PAYMENTS_FAILURE = 'GET_CRYPTO_PAYMENTS_FAILURE';
export const GET_BANK_PAYMENTS_REQUEST = 'GET_BANK_PAYMENTS_REQUEST';
export const GET_BANK_PAYMENTS_SUCCESS = 'GET_BANK_PAYMENTS_SUCCESS';
export const GET_BANK_PAYMENTS_FAILURE = 'GET_BANK_PAYMENTS_FAILURE';
export const UPDATE_BANK_PAYMENT_REQUEST = 'UPDATE_BANK_PAYMENT_REQUEST';
export const UPDATE_BANK_PAYMENT_SUCCESS = 'UPDATE_BANK_PAYMENT_SUCCESS';
export const UPDATE_BANK_PAYMENT_FAILURE = 'UPDATE_BANK_PAYMENT_FAILURE';

export const GET_RATES_REQUEST = 'GET_RATES_REQUEST';
export const GET_RATES_SUCCESS = 'GET_RATES_SUCCESS';
export const GET_RATES_FAILURE = 'GET_RATES_FAILURE';

export const CREATE_NEW_PAYMENT_REQUEST = 'CREATE_NEW_PAYMENT_REQUEST';
export const CREATE_NEW_PAYMENT_SUCCESS = 'CREATE_NEW_PAYMENT_SUCCESS';
export const CREATE_NEW_PAYMENT_FAILURE = 'CREATE_NEW_PAYMENT_FAILURE';

export const UPDATE_PAYMENT_STATUS_REQUEST = 'UPDATE_PAYMENT_STATUS_REQUEST';
export const UPDATE_PAYMENT_STATUS_SUCCESS = 'UPDATE_PAYMENT_STATUS_SUCCESS';
export const UPDATE_PAYMENT_STATUS_FAILURE = 'UPDATE_PAYMENT_STATUS_FAILURE';

export const UPDATE_PAYMENT_DATA_REQUEST = 'UPDATE_PAYMENT_DATA_REQUEST';
export const UPDATE_PAYMENT_DATA_SUCCESS = 'UPDATE_PAYMENT_DATA_SUCCESS';
export const UPDATE_PAYMENT_DATA_FAILURE = 'UPDATE_PAYMENT_DATA_FAILURE';

export const GET_INVOICES_TO_CREATE_PAYMENT_REQUEST = 'GET_INVOICES_TO_CREATE_PAYMENT_REQUEST';
export const GET_INVOICES_TO_CREATE_PAYMENT_SUCCESS = 'GET_INVOICES_TO_CREATE_PAYMENT_SUCCESS';
export const GET_INVOICES_TO_CREATE_PAYMENT_FAILURE = 'GET_INVOICES_TO_CREATE_PAYMENT_FAILURE';

export const GET_CURRENT_INVOICE_FOR_CREATE_PAYMENT_REQUEST = 'GET_CURRENT_INVOICE_FOR_CREATE_PAYMENT_REQUEST';
export const GET_CURRENT_INVOICE_FOR_CREATE_PAYMENT_SUCCESS = 'GET_CURRENT_INVOICE_FOR_CREATE_PAYMENT_SUCCESS';
export const GET_CURRENT_INVOICE_FOR_CREATE_PAYMENT_FAILURE = 'GET_CURRENT_INVOICE_FOR_CREATE_PAYMENT_FAILURE';

export const ERASE_INVOICES = 'ERASE_INVOICES';
export const CLEAR_CRYPTO_PAYMENTS = 'CLEAR_CRYPTO_PAYMENTS';
export const CLEAR_BANK_PAYMENTS = 'CLEAR_BANK_PAYMENTS';
export const CLEAR_DEPOSITS = 'CLEAR_DEPOSITS';
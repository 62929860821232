import * as paymentsActions from '../../actions/paymentsActions/constants'

const initialState = {
    rates: null,
    isLoadRates: false,
    paymentsCrypto: null,
    isLoadingCryptoPayments: true,
    paymentsBank: null,
    isLoadingBankPayments: true,
    deposits: null,
    isLoadingDeposits: true,
    invoices: null,
    currentInvoice: null,
    isPaymentEditing: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case paymentsActions.GET_DEPOSITS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case paymentsActions.GET_DEPOSITS_SUCCESS:
            return {
                ...state,
                deposits: action.payload,
                isLoadingDeposits: false,
            };
        case paymentsActions.GET_DEPOSITS_FAILURE:
            return {
                ...state,
                deposits: null,
                isLoadingDeposits: false,
            };
        case paymentsActions.GET_CRYPTO_PAYMENTS_REQUEST:
            return {
                ...state,
                isLoadingCryptoPayments: true,
            };
        case paymentsActions.GET_CRYPTO_PAYMENTS_SUCCESS:
            return {
                ...state,
                paymentsCrypto: action.payload,
                isLoadingCryptoPayments: false,
            };
        case paymentsActions.GET_CRYPTO_PAYMENTS_FAILURE:
            return {
                ...state,
                paymentsCrypto: null,
                isLoadingCryptoPayments: false,
            };
        case paymentsActions.GET_BANK_PAYMENTS_REQUEST:
            return {
                ...state,
                isLoadingBankPayments: true,
            };
        case paymentsActions.GET_BANK_PAYMENTS_SUCCESS:
            return {
                ...state,
                paymentsBank: action.payload,
                isLoadingBankPayments: false,
            };
        case paymentsActions.GET_BANK_PAYMENTS_FAILURE:
            return {
                ...state,
                paymentsBank: null,
                isLoadingBankPayments: false,
            };
        case paymentsActions.UPDATE_BANK_PAYMENT_REQUEST:
            return {
                ...state,
                isPaymentEditing: true,
            };
        case paymentsActions.UPDATE_BANK_PAYMENT_SUCCESS:
            return {
                ...state,
                isPaymentEditing: false
            };
        case paymentsActions.UPDATE_BANK_PAYMENT_FAILURE:
            return {
                ...state,
                isPaymentEditing: false,
            };
        case paymentsActions.GET_RATES_REQUEST:
            return {
                ...state,
                isLoadRates: true,
            };
        case paymentsActions.GET_RATES_SUCCESS:
            return {
                ...state,
                isLoadRates: false,
                rates: action.payload,
            };
        case paymentsActions.GET_RATES_FAILURE:
            return {
                ...state,
                rates: null,
                isLoadRates: false,
            };
        case paymentsActions.UPDATE_PAYMENT_DATA_REQUEST:
            return {
                ...state,
                isPaymentEditing: true,
            };
        case paymentsActions.UPDATE_PAYMENT_DATA_SUCCESS:
            return {
                ...state,
                isPaymentEditing: false,
            };
        case paymentsActions.UPDATE_PAYMENT_DATA_FAILURE:
            return {
                ...state,
                isPaymentEditing: false,
            };
        case paymentsActions.GET_INVOICES_TO_CREATE_PAYMENT_REQUEST:
            return {
                ...state,
            };
        case paymentsActions.GET_INVOICES_TO_CREATE_PAYMENT_SUCCESS:
            return {
                ...state,
                invoices: action.payload,
            };
        case paymentsActions.GET_INVOICES_TO_CREATE_PAYMENT_FAILURE:
            return {
                ...state,
            };
        case paymentsActions.ERASE_INVOICES:
            return {
                ...state,
                invoices: null
            };
        case paymentsActions.GET_CURRENT_INVOICE_FOR_CREATE_PAYMENT_SUCCESS:
            return {
                ...state,
                currentInvoice: action.payload,
            };
        case paymentsActions.CLEAR_CRYPTO_PAYMENTS:
            return {
                ...state,
                isLoadingCryptoPayments: true,
                paymentsCrypto: null
            };
        case paymentsActions.CLEAR_BANK_PAYMENTS:
            return {
                ...state,
                isLoadingBankPayments: true,
                paymentsBank: null
            };
        case paymentsActions.CLEAR_DEPOSITS:
            return {
                ...state,
                isLoadingDeposits: true,
                deposits: null
            };
        default:
            return state
    }
}
export const GET_STRATEGIES_REQUEST = 'GET_STRATEGIES_REQUEST';
export const GET_STRATEGIES_SUCCESS = 'GET_STRATEGIES_SUCCESS';
export const GET_STRATEGIES_FAILURE = 'GET_STRATEGIES_FAILURE';

export const CREATE_STRATEGY_REQUEST = "CREATE_STRATEGY_REQUEST"
export const CREATE_STRATEGY_SUCCESS = "CREATE_STRATEGY_SUCCESS"
export const CREATE_STRATEGY_FAILURE = "CREATE_STRATEGY_FAILURE"
export const UPDATE_STRATEGY_REQUEST = "UPDATE_STRATEGY_REQUEST"
export const UPDATE_STRATEGY_SUCCESS = "UPDATE_STRATEGY_SUCCESS"
export const UPDATE_STRATEGY_FAILURE = "UPDATE_STRATEGY_FAILURE"

import * as tradingActions from '../../actions/tradingActions/constants'

const initialState = {
    providers: null,
    isLoading: true,
    isProcessedProvider: false,
    platforms: null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case tradingActions.GET_PROVIDERS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case tradingActions.GET_PROVIDERS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                providers: action.payload,
            };
        case tradingActions.GET_PROVIDERS_FAILURE:
            return {
                ...state,
                providers: null,
                isLoading: false,
            };
        case tradingActions.CREATE_PROVIDER_REQUEST:
            return {
                ...state,
                isProcessedProvider: true,
            };
        case tradingActions.CREATE_PROVIDER_SUCCESS:
            return {
                ...state,
                isProcessedProvider: false,
            };
        case tradingActions.CREATE_PROVIDER_FAILURE:
            return {
                ...state,
                isProcessedProvider: false,
            };
        case tradingActions.UPDATE_PROVIDER_REQUEST:
            return {
                ...state,
                isProcessedProvider: true,
            };
        case tradingActions.UPDATE_PROVIDER_SUCCESS:
            return {
                ...state,
                isProcessedProvider: false,
            };
        case tradingActions.UPDATE_PROVIDER_FAILURE:
            return {
                ...state,
                isProcessedProvider: false,
            };
        case tradingActions.DELETE_PROVIDER_REQUEST:
            return {
                ...state,
                isProcessedProvider: true,
            };
        case tradingActions.DELETE_PROVIDER_SUCCESS:
            return {
                ...state,
                isProcessedProvider: false,
            };
        case tradingActions.DELETE_PROVIDER_FAILURE:
            return {
                ...state,
                isProcessedProvider: false,
            };
        case tradingActions.GET_PLATFORMS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case tradingActions.GET_PLATFORMS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                platforms: action.payload,
            };
        case tradingActions.GET_PLATFORMS_FAILURE:
            return {
                ...state,
                platforms: null,
                isLoading: false,
            };
        case tradingActions.CREATE_PLATFORM_REQUEST:
            return {
                ...state,
                isProcessedProvider: true,
            };
        case tradingActions.CREATE_PLATFORM_SUCCESS:
            return {
                ...state,
                isProcessedProvider: false,
            };
        case tradingActions.CREATE_PLATFORM_FAILURE:
            return {
                ...state,
                isProcessedProvider: false,
            };
        case tradingActions.UPDATE_PLATFORM_REQUEST:
            return {
                ...state,
                isProcessedProvider: true,
            };
        case tradingActions.UPDATE_PLATFORM_SUCCESS:
            return {
                ...state,
                isProcessedProvider: false,
            };
        case tradingActions.UPDATE_PLATFORM_FAILURE:
            return {
                ...state,
                isProcessedProvider: false,
            };
        case tradingActions.DELETE_PLATFORM_REQUEST:
            return {
                ...state,
                isProcessedProvider: true,
            };
        case tradingActions.DELETE_PLATFORM_SUCCESS:
            return {
                ...state,
                isProcessedProvider: false,
            };
        case tradingActions.DELETE_PLATFORM_FAILURE:
            return {
                ...state,
                isProcessedProvider: false,
            };
        default:
            return state
    }
}
import * as optionsActions from '../../actions/optionsActions/constants'

const initialState = {
    options: [],
    currencies: []
};

export default (state = initialState, action) => {
    switch (action.type) {
        case optionsActions.GET_OPTIONS_REQUEST:
            return {
                ...state
            };
        case optionsActions.GET_OPTIONS_SUCCESS:
            return {
                options: action.payload,
            };
        case optionsActions.GET_OPTIONS_FAILURE:
            return {
                ...state
            };
        case optionsActions.GET_CURRENCIES_REQUEST:
            return {
                ...state
            };
        case optionsActions.GET_CURRENCIES_SUCCESS:
            return {
                ...state,
                currencies: action.payload,
            };
        case optionsActions.GET_CURRENCIES_FAILURE:
            return {
                ...state
            };
        default:
            return state
    }
}
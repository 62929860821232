import * as strategiesActions from '../../actions/strategiesActions/constants'

const initialState = {
    strategies: null,
    isLoading: true,
    isProcessedStrategy: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case strategiesActions.GET_STRATEGIES_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case strategiesActions.GET_STRATEGIES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                strategies: action.payload,
            };
        case strategiesActions.GET_STRATEGIES_FAILURE:
            return {
                ...state,
                strategies: null,
                isLoading: false,
            };
        case strategiesActions.CREATE_STRATEGY_REQUEST:
            return {
                ...state,
                isProcessedStrategy: true,
            };
        case strategiesActions.CREATE_STRATEGY_SUCCESS:
            return {
                ...state,
                isProcessedStrategy: false,
            };
        case strategiesActions.CREATE_STRATEGY_FAILURE:
            return {
                ...state,
                isProcessedStrategy: false,
            };
        case strategiesActions.UPDATE_STRATEGY_REQUEST:
            return {
                ...state,
                isProcessedStrategy: true,
            };
        case strategiesActions.UPDATE_STRATEGY_SUCCESS:
            return {
                ...state,
                isProcessedStrategy: false,
            };
        case strategiesActions.UPDATE_STRATEGY_FAILURE:
            return {
                ...state,
                isProcessedStrategy: false,
            };
        default:
            return state
    }
}
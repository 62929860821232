
export const GET_WITHDRAWALS_REQUEST = 'GET_WITHDRAWALS_REQUEST';
export const GET_WITHDRAWALS_SUCCESS = 'GET_WITHDRAWALS_SUCCESS';
export const GET_WITHDRAWALS_FAILURE = 'GET_WITHDRAWALS_FAILURE';

export const GET_WITHDRAWALS_SCHEDULE_REQUEST = 'GET_WITHDRAWALS_SCHEDULE_REQUEST';
export const GET_WITHDRAWALS_SCHEDULE_SUCCESS = 'GET_WITHDRAWALS_SCHEDULE_SUCCESS';
export const GET_WITHDRAWALS_SCHEDULE_FAILURE = 'GET_WITHDRAWALS_SCHEDULE_FAILURE';

export const WITHDRAWALS_SCHEDULE_CREATE_REQUEST = 'WITHDRAWALS_SCHEDULE_CREATE_REQUEST';
export const WITHDRAWALS_SCHEDULE_CREATE_SUCCESS = 'WITHDRAWALS_SCHEDULE_CREATE_SUCCESS';
export const WITHDRAWALS_SCHEDULE_CREATE_FAILURE = 'WITHDRAWALS_SCHEDULE_CREATE_FAILURE';

export const WITHDRAWALS_SCHEDULE_EDIT_REQUEST = 'WITHDRAWALS_SCHEDULE_EDIT_REQUEST';
export const WITHDRAWALS_SCHEDULE_EDIT_SUCCESS = 'WITHDRAWALS_SCHEDULE_EDIT_SUCCESS';
export const WITHDRAWALS_SCHEDULE_EDIT_FAILURE = 'WITHDRAWALS_SCHEDULE_EDIT_FAILURE';

export const UPDATE_WITHDRAWAL_REQUEST = 'UPDATE_WITHDRAWAL_REQUEST';
export const UPDATE_WITHDRAWAL_SUCCESS = 'UPDATE_WITHDRAWAL_SUCCESS';
export const UPDATE_WITHDRAWAL_FAILURE = 'UPDATE_WITHDRAWAL_FAILURE';

export const CLEAR_WITHDRAWALS = 'CLEAR_WITHDRAWALS';

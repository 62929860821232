import * as withdrawalsActions from '../../actions/withdrawalsActions/constants'

const initialState = {
    withdrawals: null,
    isLoading: true,
    withdrawalsSchedule: null,
    isLoadingSchedule: true,
    isProceedSchedule: false,
    isWithdrawalEditing: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case withdrawalsActions.GET_WITHDRAWALS_REQUEST:
            return {
                ...state,
                isLoading: true,
            };
        case withdrawalsActions.GET_WITHDRAWALS_SUCCESS:
            return {
                ...state,
                withdrawals: action.payload,
                isLoading: false,
            };
        case withdrawalsActions.GET_WITHDRAWALS_FAILURE:
            return {
                ...state,
                withdrawals: null,
                isLoading: false,
            };
        case withdrawalsActions.GET_WITHDRAWALS_SCHEDULE_REQUEST:
            return {
                ...state,
                isLoadingSchedule: true,
            };
        case withdrawalsActions.GET_WITHDRAWALS_SCHEDULE_SUCCESS:
            return {
                ...state,
                withdrawalsSchedule: action.payload,
                isLoadingSchedule: false,
            };
        case withdrawalsActions.GET_WITHDRAWALS_SCHEDULE_FAILURE:
            return {
                ...state,
                withdrawalsSchedule: null,
                isLoadingSchedule: false,
            };
        case withdrawalsActions.WITHDRAWALS_SCHEDULE_CREATE_REQUEST:
            return {
                ...state,
                isProceedSchedule: true,
            };
        case withdrawalsActions.WITHDRAWALS_SCHEDULE_CREATE_SUCCESS:
            return {
                ...state,
                withdrawalsSchedule: action.payload,
                isProceedSchedule: false,
            };
        case withdrawalsActions.WITHDRAWALS_SCHEDULE_CREATE_FAILURE:
            return {
                ...state,
                withdrawalsSchedule: null,
                isProceedSchedule: false,
            };
        case withdrawalsActions.WITHDRAWALS_SCHEDULE_EDIT_REQUEST:
            return {
                ...state,
                isProceedSchedule: true,
            };
        case withdrawalsActions.WITHDRAWALS_SCHEDULE_EDIT_SUCCESS:
            return {
                ...state,
                withdrawalsSchedule: action.payload,
                isProceedSchedule: false,
            };
        case withdrawalsActions.WITHDRAWALS_SCHEDULE_EDIT_FAILURE:
            return {
                ...state,
                withdrawalsSchedule: null,
                isProceedSchedule: false,
            };
        case withdrawalsActions.UPDATE_WITHDRAWAL_REQUEST:
            return {
                ...state,
                isWithdrawalEditing: true,
            };
        case withdrawalsActions.UPDATE_WITHDRAWAL_SUCCESS:
            return {
                ...state,
                isWithdrawalEditing: false,
            };
        case withdrawalsActions.UPDATE_WITHDRAWAL_FAILURE:
            return {
                ...state,
                isWithdrawalEditing: false,
            };
        case withdrawalsActions.CLEAR_WITHDRAWALS:
            return {
                ...state,
                withdrawals: null,
                isLoading: true
            };
        default:
            return state
    }
}
export const GET_PROVIDERS_REQUEST = 'GET_PROVIDERS_REQUEST';
export const GET_PROVIDERS_SUCCESS = 'GET_PROVIDERS_SUCCESS';
export const GET_PROVIDERS_FAILURE = 'GET_PROVIDERS_FAILURE';

export const CREATE_PROVIDER_REQUEST = 'CREATE_PROVIDER_REQUEST';
export const CREATE_PROVIDER_SUCCESS = 'CREATE_PROVIDER_SUCCESS';
export const CREATE_PROVIDER_FAILURE = 'CREATE_PROVIDER_FAILURE';

export const UPDATE_PROVIDER_REQUEST = 'UPDATE_PROVIDER_REQUEST';
export const UPDATE_PROVIDER_SUCCESS = 'UPDATE_PROVIDER_SUCCESS';
export const UPDATE_PROVIDER_FAILURE = 'UPDATE_PROVIDER_FAILURE';

export const DELETE_PROVIDER_REQUEST = 'DELETE_PROVIDER_REQUEST';
export const DELETE_PROVIDER_SUCCESS = 'DELETE_PROVIDER_SUCCESS';
export const DELETE_PROVIDER_FAILURE = 'DELETE_PROVIDER_FAILURE';

export const GET_PLATFORMS_REQUEST = 'GET_PLATFORMS_REQUEST';
export const GET_PLATFORMS_SUCCESS = 'GET_PLATFORMS_SUCCESS';
export const GET_PLATFORMS_FAILURE = 'GET_PLATFORMS_FAILURE';

export const CREATE_PLATFORM_REQUEST = 'CREATE_PLATFORM_REQUEST';
export const CREATE_PLATFORM_SUCCESS = 'CREATE_PLATFORM_SUCCESS';
export const CREATE_PLATFORM_FAILURE = 'CREATE_PLATFORM_FAILURE';

export const UPDATE_PLATFORM_REQUEST = 'UPDATE_PLATFORM_REQUEST';
export const UPDATE_PLATFORM_SUCCESS = 'UPDATE_PLATFORM_SUCCESS';
export const UPDATE_PLATFORM_FAILURE = 'UPDATE_PLATFORM_FAILURE';

export const DELETE_PLATFORM_REQUEST = 'DELETE_PLATFORM_REQUEST';
export const DELETE_PLATFORM_SUCCESS = 'DELETE_PLATFORM_SUCCESS';
export const DELETE_PLATFORM_FAILURE = 'DELETE_PLATFORM_FAILURE';
